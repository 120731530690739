import React, { Component } from 'react'
import {
    Container, Button, Header, Form, Input, TextArea,
    Message, Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import axios from "axios";
import { localhost, cursosURL } from "../constants";
//import { Link } from "react-router-dom";
import logo from '../img/logo-02.png';

class ContatoForm extends Component {
    state = {
        error: null,
        loading: false,
        cursos: []
    };

    componentDidMount() {
        this.handleFetchCursos();
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('email', event.target.elements.email.value);        
        form_data.append('fone', event.target.elements.fone.value);
        form_data.append('endereco', event.target.elements.endereco.value);
        form_data.append('bairro', event.target.elements.bairro.value);
        form_data.append('cidade', event.target.elements.cidade.value);
        form_data.append('estado', event.target.elements.estado.value);
        form_data.append('curso', event.target.elements.curso.value);
        form_data.append('observacao', event.target.elements.observacao.value);


        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        axios.post(`https://${localhost}/contato/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.setState({ loading: false });
                    this.props.history.push(`/`);
                }
                console.log(res)
            })
    };

    handleFetchCursos = () => {
        this.setState({ loading: true });
        axios
            .get(`${cursosURL}`)
            .then(res => {
                this.setState({
                    cursos: res.data.map(curso => {
                        return {
                            key: curso.id,
                            text: `${curso.curso}`,
                            value: curso.id,
                        };
                    }),
                    totalMsg: res.data.length,
                    loading: false,
                });
                console.log(this.state.cursos)
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };
    
    render() {
        const { error, loading, cursos } = this.state;
        return (
            <Container>
                <br /> <br />
                <br />
                <Image wrapped size='medium' src={logo} />
                
                <Header><strong>Formulário de Contato: </strong></Header>

                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                
                <Form onSubmit={this.handleFormSubmit}>
                    
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Nome' required />
                        <Form.Field control={Input} name="email" label='Email' placeholder='Email' required />                        
                        <Form.Field control={Input} name="fone" label='Telefone' placeholder='Telefone' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="endereco" label='Endereço' placeholder='Endereço'  />
                        <Form.Field control={Input} name="bairro" label='Bairro' placeholder='Bairro'  />
                        <Form.Field control={Input} name="cidade" label='Cidade' placeholder='Cidade'  />
                        <Form.Field control={Input} name="estado" label='Estado' placeholder='Estado'  />
                    </Form.Group>                    

                    Assunto:
                        <select name="curso">
                        <option value='Vendas'>Vendas</option>
                        <option value='Financeiro'>Financeiro</option>
                        <option value='RH'>Recursos Humanos</option>
                        <option value='Engenharia'>Engenharia</option>

                        {cursos.map(curso => {
                            return (
                                <option value={curso.text}>{curso.text}</option>
                            )
                        })}

                        </select>
                        <br />
                    
                    <Form.Field control={TextArea} name="observacao" label='Descrição' placeholder='Descreva o assunto' required />

                    <Form.Field control={Button} color='red'>Enviar</Form.Field>

                </Form>
            </Container>
        )
    }
}

export default ContatoForm;