import React from "react";
//import { withRouter } from "react-router-dom";
//import { connect } from "react-redux";
import {
    Container,
    Header,
    Image,
    List,
    Message,
    Segment,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { mensagensURL, localhost} from "../constants";



class Blog extends React.Component {
    state = {
        error: null,
        loading: false,   
        dados: [],
    };

    componentDidMount() {
        this.handleFetchMensagens();
    }

    handleFetchMensagens = () => {
        this.setState({ loading: true });
        authAxios
            .get(`${mensagensURL}`)
            .then(res => {
                this.setState({
                    dados: res.data, 
                    loading: false,
                });
                console.log("Blog.js handleFetchMensagens GET data:");
                console.log(res.data);
                console.log(this.state)
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

        
    render() {
        const { error, loading, dados,  } = this.state;
        console.log("Blog.js render ");
        console.log(dados)
        return (  
            <Container>  
                <br /><br />
                {error && (
                    <Message
                        error
                        header="Ocorram alguns erros com seus dados"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}


                <Header style={{ color: "green" }}>BLOG</Header>

               
               {dados.map(mensagem => {
                console.log(mensagem)
                return (
                    <List>
                    <List.Item>
                            <Image avatar src={`http://${localhost}${mensagem.user.avatar}`} />
                        <List.Content>
                                <List.Header as='a'>{mensagem.user.user}</List.Header>
                            <List.Description>
                                    {mensagem.mensagem} <br />
                                    {/*<small> <Moment format="DD/MM/YYYY <hh:mm a>"> {mensagem.created} </Moment></small> */}
                                        <small> {mensagem.created} </small>

                            </List.Description>
                        </List.Content>  
                    </List.Item>             
                    </List>
                );
                })}
                
                
                
                                                           
                
            </Container>
        );
        }
}


export default Blog;
