//export const localhost = "192.168.1.11:8000";
//export const localhost = "dev.araujonetworks.com.br:8001";
export const localhost = "www.rodomil.com.br";
//export const localhost = "app.rodomil.com.br:8007";



const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;


export const cursosURL = `https://${localhost}/cursos/`;
export const grupoURL = `https://${localhost}/blog/`;
export const mensagensURL = `https://${localhost}/mensagens/`;
