import React from "react";
import {
    Container,    
    Image,
    List,
    Divider,
    Message,
    Segment,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import { cursosURL, } from "../constants";
import axios from "axios";


class Eventos extends React.Component {

    state = {
        error: null,
        loading: false,
        cursos: [],
    };

    componentDidMount() {
        this.handleFetchCursos();
    }

    handleFetchCursos = () => {
        this.setState({ loading: true });
        axios
            .get(`${cursosURL}`)
            .then(res => {
                this.setState({
                    cursos: res.data,
                    nome: res.data[0].user,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {

        const { error, loading, cursos, } = this.state;

        return (
            <Container>

                <br /><br />
                {error && (
                    <Message
                        error
                        header="Ocorram alguns erros com seus dados"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {cursos.map((curso, i) => {
                    return (


                        <List>


                            <List.Item key={curso.id}>                                

                                <Image avatar src={curso.logo} />
                                <List.Content>
                                    <List.Header as='a'>{curso.curso}</List.Header>
                                    <List.Description>
                                        <small> <b>{curso.descricao} </b><br />

                                        </small>
                                        <br />

                                    </List.Description>
                                </List.Content>
                                <Divider />
                                
                            </List.Item>


                        </List>
                    );
                })}


            </Container>
        );
    }
}

export default Eventos;
