import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Select,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
//import logo from './img/img-01.png';
//import axios from "axios";

class CustomLayout extends React.Component {

  state = {
    error: null,
    loading: false,
    grupo: null,
    descricao: null,
    logo: null,
  };

  componentDidMount() {

  }

  render() {
    //const { authenticated, cart, loading } = this.props;
    const { authenticated } = this.props;
    const { grupo, logo } = this.state;

    const selectOptions = [
      { key: 'af', value: 'af', text: 'Link Um' },
      { key: 'ax', value: 'ax', text: 'Link Dois' },
      { key: 'al', value: 'al', text: 'Link Três' },
      { key: 'dz', value: 'dz', text: 'Link Quatro' },
    ]

    return (
      <div>
        <Menu inverted fixed="top" color="red">
          <Container>

            <Link to="/">
              <Menu.Item header>Home</Menu.Item>
            </Link>

            <Link to="/quemsomos">
              <Menu.Item header>Quem somos</Menu.Item>
            </Link>

            <Link to="/eventos">
              <Menu.Item header>Produtos</Menu.Item>
            </Link>    

            {authenticated ? (
              <React.Fragment>
                {/* 
                <Link to="/agenda">
                  <Menu.Item header>Painel</Menu.Item>
                </Link>

                <Link to="/cadastros">
                  <Menu.Item header>Cadastros</Menu.Item>
                </Link> 
                */}
                <Menu.Menu position="right" >
                  <Dropdown   
                    text="Rodomil"                 
                    icon="pallet"
                    //loading={loading}
                    //text={`${cart !== null ? cart.order_items.length : 0}`}
                    pointing
                    className="link item"     
                                 
                  >
                    <Dropdown.Menu > 
                      <Dropdown.Item
                        icon="comments"
                        text="Blog's"
                        onClick={() =>
                          this.props.history.push("/cursos")
                        }
                      />

                      {(grupo === "admin" || grupo === "parceiro" || grupo === "profissional" || grupo === "paciente") ? (

                      <Dropdown.Item
                        icon="address card outline"
                        text="cadastros"                        
                        onClick={() =>
                          this.props.history.push("/cadastros")
                        }
                      />
                      ) : ""}

                      <Dropdown.Item
                        icon="chart line"
                        text="relatórios"
                        onClick={() =>
                          this.props.history.push("/relatorios")
                        }
                      />

                      {(grupo === "admin") ? (

                      <Dropdown.Item
                        href="http://127.0.0.1:8000/admin/"
                        icon="globe"
                        text="admin"
                        target="_blank"
                      />

                      ) : ""}

                    </Dropdown.Menu>
                  </Dropdown>
                  <Menu.Item header onClick={() => this.props.logout()}>
                    <Link to="/">
                    Logout
                    </Link>
                  </Menu.Item>
                </Menu.Menu>
              </React.Fragment>
            ) : 
            
            (
              <Menu.Menu position="right">
                <Link to="/login">
                  <Menu.Item header>Login</Menu.Item>
                </Link>
                
              </Menu.Menu>
            )}
            <Link to="/contato">
              <Menu.Item header>Contato</Menu.Item>
            </Link>   

          </Container>
        </Menu>

        {this.props.children}

        <Segment
          color="red"
          inverted
          vertical
          style={{ margin: "5em 0em 0em", padding: "5em 0em", }}
        >
          <Container textAlign="center" >
            <Grid divided inverted stackable>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="*Colaboradores" />
                <List link >
                  
                    <Select placeholder='Selecione Colaborador' options={selectOptions} />
                  
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="*ONGs" />
                <List link >

                  <Select placeholder='Selecione ONG' options={selectOptions} />

                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="*Parceiros" />
                <List link >

                  <Select placeholder='Selecione Parceiro' options={selectOptions} />

                </List>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header inverted as="h4" content="Rodomil Ferros e Aços Ltda" />
                <p>
                  Av. Jose Andraus Gassani, 5005 - Distrito Industrial - Uberlândia - MG
                  <br />
                  Fone:  (34) 3230-2100 - WhatsApp:  (34) 99672-4100
                  <br />
                  Email: contato@rodomil.com.br
                  <br />
                  * Links ainda não liberado para publico.
                </p>
              </Grid.Column>
            </Grid>

            <Divider inverted section />
            <Image centered size="mini" src={ logo } />
            <List horizontal inverted divided link size="small">
              <List.Item as="a" href="#">
                Mapa do Site
              </List.Item>
              <List.Item as="a" href="#">
                Contate-nos
              </List.Item>
              <List.Item as="a" href="#">
                Termos e Condições
              </List.Item>
              <List.Item as="a" href="#">
                Política de privacidade
              </List.Item>
            </List>
          </Container>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    //cart: state.cart.shoppingCart,
    //loading: state.cart.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    //fetchCart: () => dispatch(fetchCart())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
