import React from "react";
import {
    Container,
    Divider,
    Image,
    Label,
} from "semantic-ui-react";
//import ferros from '../img/Ferros.png';
//import aco from '../img/Aco.png';
import logo from '../img/logo-02.png';

class QuemSomos extends React.Component {

    componentDidMount() {

    }

    render() {

        return (
            <Container>
                <br /><br /><br />
                
                <Image wrapped size='medium' src={logo} />
                
                <p style={{ fontSize: "1.13em", textAlign: 'justify' }}>
                    <br />
                A Rodomil conta com toda estrutura formada e adaptada em pleno funcionamento, 
                        com profissionais altamente capacitados, prontos a atender e oferecer a você 
                        produtos da mais alta qualidade e a preços justos!
                <br /><br />
                Na Rodomil, sua empresa encontra uma grande variedade de produtos siderúrgicos:<br />
                    Vigas I, U, H, L, ferro chato, ferro redondo laminado e trefilado, chapa lisa,
                     chapa xadrez, perfis soldados e perfis W.              
                <br /><br />
                Os produtos são de qualidade, e o atendimento, melhor ainda: a Rodomil está sempre 
                    ampliando seus estoques e conta com um sistema informatizado de última geração que
                     agilizam as respostas às suas necessidades.
                </p>
                
                <Divider />
                <p style={{ fontSize: "1.13em", textAlign: 'justify' }}>                   
                    <Label color="red" ribbon>
                        Objetivos
                    </Label>
                    <br />   
                    • Excelência técnica<br />
                    • Custo competitivo<br />
                    • Rapidez e eficiência<br />
                    • Comprovação do retorno do investimento<br />
                    • Soluções robustas e inteligentes<br />
                    • Diversos segmentos de mercado e tipos de aplicações<br />
                    <br />  
                    <Label color="red" ribbon>
                        Missão
                    </Label>  
                    <br />
    Atuar de forma sustentável e rentável, com ética, responsabilidade social e ambiental, oferecendo
                     produtos e serviços de excelência nos segmentos Ferro e Aço, Corte e Dobra. <br />
                    <br />
                    <Label color="red" ribbon>
                        Visão
                    </Label> 
                    <br />
    Sermos referência no mercado de Ferro e Aço, Corte e Dobra. <br />
                    <br />
                    <Label color="red" ribbon>
                        Valores
                    </Label>
                    <br />
Integridade, honestidade e respeito no relacionamento com nossos clientes, funcionários e concorrentes.
 Valorização de nossos colaboradores promovendo boas práticas e trabalho em equipe e liderança através 
 do conhecimentos das pessoas, dos processos e mercados.  <br />
                <br />
                <Label color="red" ribbon>
                    Mercado
                </Label>
                <br />
                    •  Grandes construtoras; <br />
                    •  Fabricantes de estruturas metálicas; <br />
                    •  Empresas de engenharia; <br />
                    •  Indústrias metalúrgicas; <br />
                    •  Instituições públicas; <br />
                    •  Comércios; <br />
                    •  Serralherias; <br />
                    •  Usinas; <br />
                    •  Revendedoras, entre outros.<br />
                <br />

                <ul>
                   
                </ul>
                </p>

            </Container>
        );
    }
}

export default QuemSomos;
