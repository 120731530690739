import React from "react";
//import { withRouter } from "react-router-dom";
//import { connect } from "react-redux";
import {
    Button,
    Container,
    Divider,
    Form,
    Grid,
    Header,
    Input,
    Statistic,
    Image,
    List,
    Message,
    Segment,
    TextArea,
    Dimmer,
    Label,
    Loader,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { cursosURL, localhost, mensagensURL, } from "../constants";
import logo from '../img/logo-02.png';
import axios from "axios";
import Moment from 'react-moment';


class Blogs extends React.Component {
    state = {
        error: null,
        loading: false,
        cursos: [],
        photo: null,
        nome: null,
        dados: [],
        curso: null,
        nomeCurso: null,
        saving: false,
        success: false,
        formData: {
            titulo: '',
            mensagem: '',
            default: false
        }
    };

    componentDidMount() {
        this.handleFetchCursos();
        this.handleFetchMensagens();
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, saving: true });
        const { formData } = this.state;

        let form_data = new FormData();
        form_data.append('curso', this.state.nomeCurso);                     
        form_data.append('titulo', event.target.elements.titulo.value);
        form_data.append('mensagem', event.target.elements.mensagem.value);        

        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        authAxios.post(`https://${localhost}/postar/`, {...formData, curso: this.state.nomeCurso}) 
            .then(res => {
                this.setState({ loading: false, 
                                success: true, 
                                saving: false, 
                                formData: { default: false,
                                            titulo: '',
                                            mensagem: ''
                                          } 
                            });
                this.handleFetchMensagens();   
            })                  
    };

    handleFetchCursos = () => {
        this.setState({ loading: true });
        authAxios
            .get(`${cursosURL}`)
            .then(res => {
                this.setState({
                    cursos: res.data, 
                    nome: res.data[0].user,  
                    loading: false,
                });                
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };
    
    handleFetchMensagens = () => {
        this.setState({ loading: true });
        authAxios
            .get(`${mensagensURL}`)
            .then(res => {
                this.setState({
                    dados: res.data,
                    loading: false,
                });                
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFilterCurso = (curso, nome) => {
        this.setState({ loading: true });
        this.setState({ 
            loading: false, 
            curso: curso, 
            nomeCurso: nome });        
    };

    handleChange = e => {
        const { formData } = this.state;
        const updatedFormdata = {
            ...formData,
            [e.target.name]: e.target.value
        };
        this.setState({
            formData: updatedFormdata
        });
    };

       
    render() {
        const { error, loading, cursos, photo, dados, saving, success, formData } = this.state;
        return (  
            <Container>  
                <br /><br />
                {error && (
                    <Message
                        error
                        header="Ocorram alguns erros com seus dados"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                <React.Fragment>

                <Header style={{ color: "red" }}>Painel do Usuário</Header>

                <Label as='a' image >
                    {(photo !== null) ? <img src={photo} alt="" /> : <img src={logo} alt="" />}
                    Blog's
                </Label>

                <Statistic.Group>

                    {cursos.map(curso => {
                        return (
                            <Statistic>
                                <Statistic.Value>
                                    {(curso.logo !== null) ? <Image src={curso.logo} inline circular />
                                        : <Image src={logo} inline circular />}
                                    
                                </Statistic.Value>
                                <Statistic.Label>
                                    <Button 
                                        color='orange' 
                                        onClick={() => this.handleFilterCurso(curso.id, curso.curso)}                                    
                                        >
                                        {curso.curso}
                                    </Button>    
                                </Statistic.Label>
                            </Statistic>
                            
                        )
                    })
                    }

                </Statistic.Group>   

                </React.Fragment>

                <Grid columns={2} divided>

                <Grid.Row>
                <React.Fragment>
                <Grid.Column>

                {this.state.curso !== null ?
                <Header style={{ color: "red" }}>
                        <Label color="red" ribbon>
                        {this.state.nomeCurso}
                    </Label> 
                </Header>
                : '' }

                { this.state.curso !== null ? 
                
                        <Form onSubmit={this.handleFormSubmit} success={success}>

                        <Form.Group widths='equal'>
                            <Form.Field 
                                control={Input} 
                                name="titulo" 
                                label='Titulo' 
                                placeholder='Titulo' 
                                required 
                                onChange={this.handleChange}
                                value={formData.titulo}
                                />                            
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field 
                                control={TextArea} 
                                name="mensagem" 
                                label='Mensagem' 
                                placeholder='Texto a publicar' 
                                required 
                                onChange={this.handleChange}
                                value={formData.mensagem}
                                />
                        </Form.Group>

                        <Form.Button disabled={saving} loading={saving} color='red'>
                                            Postar
                        </Form.Button>

                    </Form>

                : '' }                

                </Grid.Column>
                </React.Fragment>


                <React.Fragment>
                <Grid.Column>

                    {dados.map((mensagem, i) => {
                        return (
                            <List>
                                {(mensagem.curso.id === this.state.curso) ? (

                                    <List.Item key={mensagem.id}>
                                        <Image avatar src={`https://${localhost}${mensagem.user.avatar}`} />
                                        <List.Content>
                                            <List.Header as='a'>{mensagem.user.user}</List.Header>
                                            <List.Description>
                                                <small> <b>{mensagem.titulo} </b><br />
                                                <Moment format="DD/MM/YY hh:mm a">
                                                    {mensagem.created}
                                                </Moment>
                                                </small>
                                                <br /><br />
                                                {mensagem.mensagem} 
                                            </List.Description>
                                        </List.Content>
                                        <Divider />
                                    </List.Item>

                                ) : ""}
                            </List>
                        );
                    })}

                </Grid.Column>
                </React.Fragment>

                </Grid.Row>
                </Grid>
            </Container>

            
        );
        }
}


export default Blogs;
