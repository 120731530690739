import React from "react";
import {
    Container,
    Divider,
    Grid,
    Header,
    Image,
    List,
    Message,
    Segment,
    Dimmer,
    Loader,
    Label,
    Modal,
} from "semantic-ui-react";
import { cursosURL,  } from "../constants";
import axios from "axios";
import { Link } from "react-router-dom";
import ferros from '../img/Ferros.png';
import aco from '../img/Aco.png';
import logo from '../img/logo-02.png';
import produtos from '../img/produtos.png';


class Eventos extends React.Component {

    state = {
        error: null,
        loading: false,
        cursos: [],
    };

    componentDidMount() {
        this.handleFetchCursos();
    }

    handleFetchCursos = () => {
        this.setState({ loading: true });
        axios
            .get(`${cursosURL}`)
            .then(res => {
                this.setState({
                    cursos: res.data,
                    nome: res.data[0].user,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {

        const { error, loading, cursos, } = this.state;

        return (
            <Container>

                <br /><br />
                {error && (
                    <Message
                        error
                        header="Ocorram alguns erros com seus dados"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                <br />
                <Image wrapped size='medium' src={logo} />
                <br /><br />
                <Image wrapped  src={produtos} />
                <br />
                <Divider />

                <p style={{ fontSize: "1.13em", textAlign: 'justify' }}>
                    <strong> Eventos </strong>
                </p>

                {cursos.map((curso, i) => {
                    return (
                        
                        <List>                            

                            <List.Item key={curso.id}>     

                                <Modal trigger={
                                    <Label color="red" ribbon>
                                        <Link>{curso.curso}</Link>
                                    </Label>
                                }>
                                    <Modal.Header><Image wrapped size='medium' src={logo} /></Modal.Header>
                                    <Modal.Content image>

                                        <Image wrapped size='medium' src={curso.logo} />

                                        <Modal.Description>
                                            <Header>{curso.curso}</Header>
                                            <p style={{ fontSize: "1.13em" }}>
                                                {curso.descricao}
                                            </p>

                                        </Modal.Description>
                                    </Modal.Content>

                                </Modal>  

                                
                            </List.Item>


                        </List>
                    );
                })}

                
            </Container>
        );
    }
}

export default Eventos;
