import React from "react";
import { Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import HomepageLayout from "./containers/Home";

import Confira from "./containers/Confira";

import ContatoForm from "./containers/ContatoForm";
import Cursos from "./containers/Cursos";
import Blog from "./components/Blog";
import QuemSomos from "./containers/QuemSomos";
import Eventos from "./containers/Eventos";
import Eventos2 from "./containers/Eventos2";


const BaseRouter = () => (
  <Hoc>
    <Route path="/confira" component={Confira} />    
    <Route path="/cursos" component={Cursos} />  
    <Route path="/quemsomos" component={QuemSomos} />  
    <Route path="/eventos" component={Eventos} />  
    <Route path="/eventos2" component={Eventos2} />
    <Route path="/blog" component={Blog} />  
    <Route path="/contato" component={ContatoForm} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route exact path="/" component={HomepageLayout} />
  </Hoc>
);

export default BaseRouter;
