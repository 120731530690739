import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  Label,
  Modal,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from '../img/logo-02.png';
//import banner from '../img/banner-1.png';
import cliente1 from './img/cliente-01.png';

const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class DesktopContainer extends Component {
  state = {   
  };
  

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });
  

  render() {
    const { children } = this.props;
    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        />
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        {children}
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "4em 0em" }} vertical>      
      <Grid container stackable verticalAlign="middle">

        <Grid.Row>

	<Grid.Column floated="right" width={6}>
            <Image
              bordered
              rounded
              size="medium"
              src={logo}
            />
        </Grid.Column>

          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em", color: "red" }}>
              Rodomil Ferros e Aços
            </Header>    
            <p style={{ fontSize: "1.33em", textAlign: 'justify' }}>
              A Rodomil atua como distribuidora de ferro e aço das principais usinas siderúrgicas do país,
               procurando garantir a satisfação de seus clientes com produtos de qualidade, atendimento 
               diferenciado e rapidez nas entregas.
              <br />
              A principal meta da empresa é superar suas expectativas, oferecendo sempre os melhores produtos 
              em ferro e aço em geral.
              <br />
              Contate-nos e saiba mais sobre nossos serviços.
            </p>
            <Header as="h3" style={{ fontSize: "2em", color: "red" }}>
              Empresa sustentável
            </Header>
            <p style={{ fontSize: "1.33em", textAlign: 'justify' }}>
              Tudo isso contribui para o desenvolvimento sustentável, ou seja, que não vai ser destrutivo com a 
              natureza ou com a comunidade. Uma empresa que se preocupa com essa questão geralmente possui
               programas internos e externos que ajudam o meio ambiente, os colaboradores, os clientes,
                quem está envolvido no processo dos seus produtos e serviços e daqueles que são afetados
                 de alguma forma com a atividade.

            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>

          <Grid.Column textAlign="center">
            
            {/*<Link to="/confira"><Button size="huge">Confira</Button></Link> */}

            <Modal trigger={
              <Label color="red" ribbon>
                <Link>Confira</Link>
              </Label>
            }>
              <Modal.Header>Confira !!!</Modal.Header>
              <Modal.Content image>

                <Image wrapped size='medium' src={logo} />

                <Modal.Description>
                  <Header><h1>O que o mundo corporativo ganha com isso</h1></Header>
                  <p style={{ fontSize: "1.13em" }}>

                    Agora saiba quais são as 5 principais vantagens para a organização<br />
                    <br />
  
                    1 - Crescimento do bem-estar com a preservação do meio ambiente;<br />
                    2 - Imagem positiva, contribuindo para a credibilidade e confiança junto aos consumidores, fornecedores e investidores;<br />
                    3 - Vantagem competitiva e diferenciação de mercado, abrindo espaço para a expansão de nichos;<br />
                    4 - Redução nos custos de produção, que consequentemente, geram economia nos processos da empresa;<br />
                    5 - Satisfação e aumento da produtividade do colaborador por estar em um lugar que respeita a ele, os colegas, a comunidade e ao meio ambiente.<br />
  
                  </p>

                </Modal.Description>
              </Modal.Content>

            </Modal>  

          
          </Grid.Column>

        </Grid.Row>

      </Grid>
    </Segment>
    <Segment style={{ padding: "0em" }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "Eficiência, qualidade, agilidade e custo competitivo !"
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Isso é o que nosos clientes dizem ao nosso respeito
            </p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "Empresa com excelência em atendimento."
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              <Image avatar src={cliente1} />
              <b>Emerson</b>, cliente Rodomil.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: "4em 0em" }} vertical>
      <Container text>        

        <Header as="h3" style={{ fontSize: "2em", color: "red" }}>
          Principais práticas de sustentabilidade 
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          • Implementação de sistemas de tratamento e reaproveitamento da água; <br />
          • Uso consciente da água e da energia elétrica;<br />
          • Desenvolvimento de programas de inclusão social na comunidade;<br />
          • Programas de reciclagem;<br />
          • Respeito ao gênero, opção sexual e religião do colaborador;<br />
          • muito mais ...<br />
        </p>      
        
        <Modal trigger={
          <Label color="red" ribbon>
            <Link>Vantagens</Link>
          </Label>
        }>
          <Modal.Header>Vantagens</Modal.Header>
          <Modal.Content image>

            <Image wrapped size='medium' src={logo} />

            <Modal.Description>
              <Header><h1>Principais benefícios</h1></Header>
              <p style={{ fontSize: "1.13em" }}>

                <ul>
                  
                  Esse tipo de ação funciona como um divisor de águas na empresa. Confira quais são os principais benefícios:<br />
                  <br />
                  • Mais aderência à cultura organizacional;<br />
                  • Prevenção de situações ligadas a abusos ou assédios físicos e psicológicos com o código de conduta;<br />
                  • Índices positivos de clima organizacional e de qualidade de vida no trabalho;<br />
                  • Crescimento de um quadro de funcionários ativo e criativo;<br />
                  • Mais produtividade sem perda de qualidade na entrega das atividades;<br />
                  • Relacionamentos interpessoais mais saudáveis;<br />
                  • Retenção de talentos.<br />
                </ul>
              </p>

            </Modal.Description>
          </Modal.Content>

        </Modal>  

             
        

      </Container>
    </Segment>
  </ResponsiveContainer>
);
export default HomepageLayout;
